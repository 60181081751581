import React from "react";
import Link from "next/link";
import {
  CommoditySlugs,
  ConsumenSlugs,
  CompanySlugs,
} from "../../data/DataSlugs";
import LogoWhite from "../../../public/static/images/svg/homecredit_white.svg";
import linkedinIcon from "../../../public/static/images/svg/linkedin_idle.svg";
import facebookIcon from "../../../public/static/images/svg/facebook_idle.svg";
import igIcon from "../../../public/static/images/svg/ig_idle.svg";
import twitterIcon from "../../../public/static/images/svg/twitter_idle.svg";
import youtubeIcon from "../../../public/static/images/svg/youtube_idle.svg";
import tiktokIcon from "../../../public/static/images/svg/ic_tiktok@SVG.svg";
import emailIcon from "../../../public/static/images/svg/mail_footer_idle.svg";
import phoneIcon from "../../../public/static/images/svg/phone_footer_idle.svg";
import whatsappIcon from "../../../public/static/images/svg/footer-whatsapp.svg";
import ojkIconMobile from "../../../public/static/images/png/hci_under_ojk_centered_white.png";
import ojkIconDesktop from "../../../public/static/images/png/hci_under_ojk_right_white.png";
import appStoreIcon from "../../../public/static/images/svg/app_store_badge.svg";
import playStoreIcon from "../../../public/static/images/svg/play_store_badge.svg";
import { yearConst } from "../../utils/constanta";
import { GACustomEvent } from "src/utils/analytics";

const List = ({ listName, href, path, target, event }) => {
  return (
    <li
      style={{ marginBottom: "6px" }}
      onClick={() => event && GACustomEvent(event, "click")}
    >
      {listName !== "Karir" ? (
        <Link href={href} as={path}>
          <a className="footer-item-list" target={target}>
            {listName}
          </a>
        </Link>
      ) : (
        <a className="footer-item-list" href={path} target={target}>
          {listName}
        </a>
      )}
      <style jsx>{`
        .footer-item-list {
          color: var(--color-gray-lighter);
          font-size: 12px;
        }
        .footer-item-list:hover {
          color: var(--color-white);
        }
      `}</style>
    </li>
  );
};

const Icons = ({ iconSrc, iconId, url }) => {
  return (
    <React.Fragment>
      <a target="_blank" href={url}>
        <img id={iconId} className="icons" src={iconSrc} alt="socmed-icons" />
      </a>
      <style jsx>{`
        .icons {
          height: 20px;
          margin: 0 10px 0 0;
        }
        .icons:hover {
          cursor: pointer;
        }
        @media only screen and (max-width: 600px) {
          .icons {
            margin: 10px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

const Contact = ({ href, contact, contactIcon }) => {
  return (
    <a href={href} target="_blank">
      <div className="contact-footer">
        <img src={contactIcon} alt="contact-icon" />
        <div>{contact}</div>
      </div>

      <style jsx>{`
        .contact-footer {
          color: var(--color-white);
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .contact-footer img {
          margin-right: 5px;
        }
        @media only screen and (max-width: 600px) {
          .contact-footer {
            justify-content: center;
            margin: 12px;
          }
        }
      `}</style>
    </a>
  );
};

const listFinancing = [
  {
    path: `/${CommoditySlugs.kreditHp}`,
    href: `/${CommoditySlugs.kreditHp}`,
    title: "Pembiayaan Handphone",
    event: "multiple_screens_store_purchase_phone",
  },
  {
    path: `/${CommoditySlugs.kreditKamera}`,
    href: `/${CommoditySlugs.kreditKamera}`,
    title: "Pembiayaan Kamera",
    event: "multiple_screens_store_purchase_camera",
  },
  {
    path: `/${CommoditySlugs.kreditTv}`,
    href: `/${CommoditySlugs.kreditTv}`,
    title: "Pembiayaan TV",
    event: "multiple_screens_store_purchase_tv",
  },
  {
    path: `/${CommoditySlugs.kreditLaptop}`,
    href: `/${CommoditySlugs.kreditLaptop}`,
    title: "Pembiayaan Laptop",
    event: "multiple_screens_store_purchase_laptop",
  },
  {
    path: `/${CommoditySlugs.kreditFurnitur}`,
    href: `/${CommoditySlugs.kreditFurnitur}`,
    title: "Pembiayaan Furniture",
    event: "multiple_screens_store_purchase_furniture",
  },
  {
    path: `/${CommoditySlugs.kreditGadget}`,
    href: `/${CommoditySlugs.kreditGadget}`,
    title: "Pembiayaan Gadget",
    event: "multiple_screens_store_purchase_gadget",
  },
];

const listCustomerHelp = [
  // {
  //   path: "/bayarnanti",
  //   href: "/bayarnanti",
  //   title: "Home Credit BayarNanti"
  // },
  {
    path: "/promo-dan-penawaran",
    href: "/promo-dan-penawaran",
    title: "Promo & Penawaran",
    event: "multiple_screens_promo_offers_page",
  },
  {
    path: "/panduan-konsumen",
    href: "/panduan-konsumen",
    title: "Panduan Konsumen",
    event: "multiple_screens_customer_guide_page",
  },
  {
    path: `/${ConsumenSlugs.caraPembayaran}`,
    href: "/cara-pembayaran",
    title: "Cara Pembayaran",
    event: "multiple_screens_payment_method_page",
  },
  {
    path: "/pertanyaan-umum",
    href: "/pertanyaan-umum",
    title: "Pertanyaan Umum",
    event: "multiple_screens_faq_page",
  },
  {
    path: "/temukan-lokasi-kami",
    href: "/temukan-lokasi-kami",
    title: "Temukan Lokasi Kami",
    event: "multiple_screens_locate_us_page",
  },
];

const listAbout = [
  {
    path: `/${CompanySlugs.tentangPerusahaan}`,
    href: `/${CompanySlugs.tentangPerusahaan}`,
    title: "Tentang Perusahaan",
    target: "",
    event: "multiple_screens_about_us",
  },
  {
    path: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.acaraPerusahaan}`,
    href: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.acaraPerusahaan}`,
    title: "Acara Perusahaan",
    target: "",
    event: "multiple_screens_corporate_events_page",
  },
  {
    path: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.siaranPers}`,
    href: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.siaranPers}`,
    title: "Siaran Pers",
    target: "",
    event: "multiple_screens_press_releases_page",
  },
  {
    path: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.penghargaanKami}`,
    href: `/${CompanySlugs.tentangPerusahaan}/${CompanySlugs.penghargaanKami}`,
    title: "Penghargaan Kami",
    target: "",
    event: "multiple_screens_company_awards_page",
  },
  {
    path: "/mitra",
    href: "/mitra",
    title: "Mitra",
    event: "multiple_screens_mitra_partner_page",
  },
  {
    path: "/blog",
    href: "/blog",
    title: "Blog",
    event: "multiple_screens_blog_page",
  },
  {
    path: "/hubungi-kami",
    href: "/hubungi-kami",
    title: "Hubungi Kami",
    target: "",
    event: "multiple_screens_contact_us_page",
  },
  // hide career tab, Ref: https://devops.homecredit.co.id/browse/CAC-214
  // {
  //   path: `http://career.homecredit.co.id`,
  //   href: `http://career.homecredit.co.id`,
  //   title: "Karir",
  //   target: "_blank",
  //   event: "multiple_screens_careers_page",
  // },
];

const listProtection = [
  {
    path: "/asuransi-cicilan-kredit",
    href: "/asuransi-cicilan-kredit",
    title: "AMAN",
    event: "multiple_screens_aman",
  },
  {
    title: "EASYCOVER",
    href: "/asuransi-gadget",
    path: "/asuransi-gadget",
    event: "multiple_screens_easy_cover_max_cover",
  },
  {
    title: "proSERIES / TEC PROTEC",
    href: "/asuransi-barang-elektronik",
    path: "asuransi-barang-elektronik",
    event: "multiple_screens_proseries",
  },
  {
    title: "SANTAI",
    href: "/asuransi-isi-rumah",
    path: "/asuransi-isi-rumah",
    event: "multiple_screens_santai",
  },
  {
    title: "PROTEXI",
    href: "/asuransi-tagihan-kartu-kredit",
    path: "/asuransi-tagihan-kartu-kredit",
    event: "multiple_screens_protexi",
  },
  {
    title: "CriticaLifeCOVER",
    href: "/CriticaLifeCOVER",
    path: "/CriticaLifeCOVER",
    event: "",
  },
];

const TopFooter = () => {
  return (
    <React.Fragment>
      <div className="footer-container-wrapper-top">
        <div className="footer-container-top">
          <div className="footer-wrapper-top">
            <div className="footer-top-item-1">
              <h5 className="item-title">Layanan Pembiayaan</h5>
              <div className="footer-list-wrapper">
                <ul className="footer-wrap-ul">
                  {listFinancing.map((item, index) => (
                    <List
                      listName={item.title}
                      path={item.path}
                      href={item.href}
                      key={index}
                      event={item.event}
                    />
                  ))}
                </ul>
              </div>
            </div>
            {/* HIDE MPF
            <div className="footer-top-item-2">
              <h5 className="item-title">Pembiayaan Multiguna</h5>
              <div className="footer-list-wrapper">
                <ul className="footer-wrap-ul">
                  <li style={{ marginBottom: "6px" }}>
                    <Link
                      href="/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan"
                      as="/kredit-multiguna/pinjaman-tunai-pelanggan-lama-tanpa-jaminan"
                    >
                      <a className="footer-item-list">FlexiFast</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="footer-top-item-2">
              <h5 className="item-title">Layanan Proteksi</h5>
              <div className="footer-list-wrapper">
                <ul className="footer-wrap-ul">
                  {listProtection.map((item, index) => (
                    <List
                      listName={item.title}
                      path={item.path}
                      href={item.href}
                      key={index}
                      event={item.event}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className="footer-top-item-4">
              <h5 className="item-title">Konsumen</h5>
              <ul className="footer-wrap-ul">
                {listCustomerHelp.map((item, index) => (
                  <List
                    listName={item.title}
                    path={item.path}
                    href={item.href}
                    key={index}
                    event={item.event}
                  />
                ))}
              </ul>
            </div>
            <div className="footer-top-item-5">
              <h5 className="item-title">Tentang Kami</h5>
              <ul className="footer-wrap-ul">
                {listAbout.map((item, index) => (
                  <List
                    listName={item.title}
                    path={item.path}
                    href={item.href}
                    key={index}
                    target={item.target}
                    event={item.event}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .footer-container-wrapper-top {
          display: flex;
          justify-content: center;
          background-color: var(--color-gray);
          width: 100%;
        }
        .footer-container-top {
          width: 100%;
          padding: 10px;
          font-weight: 300;
          max-width: 960px;
        }
        .footer-wrapper-top {
          padding: 36px 0 36px 0;
          display: flex;
          justify-content: space-between;
        }
        .footer-wrap-ul {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          list-style-type: none;
          padding: 0;
          font-size: 10px;
        }
        .footer-wrap-ul li {
          margin-block-end: 8px;
        }
        .footer-list-wrapper {
          display: flex;
          flex-wrap: wrap;
        }
        .item-title {
          margin-bottom: 18px;
          color: var(--color-white);
        }

        .footer-item-list {
          color: var(--color-gray-lighter);
          font-size: 12px;
        }
        .footer-item-list:hover {
          color: var(--color-white);
        }
        @media only screen and (max-width: 600px) {
          .footer-container-wrapper-top {
            display: none;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

const BottomFooter = () => {
  return (
    <React.Fragment>
      <div className="footer-container-wrapper-bot">
        <div className="footer-container-bot">
          <div className="footer-container-mobile">
            <div className="footer-bold-12">
              <p>#BisaJadiJADIBISA</p>
            </div>
            <div>
              <img className="footer-logo" src={LogoWhite} alt="logo" />
            </div>
            <div className="footer-center-mobile">
              <a
                onClick={() =>
                  GACustomEvent("multiple_screens_download_app_store", "click")
                }
                href="https://apps.apple.com/id/app/my-home-credit-indonesia/id1313113736"
              >
                <img
                  className="download-icon"
                  src={appStoreIcon}
                  alt="app-store"
                />
              </a>
              <a
                onClick={() =>
                  GACustomEvent(
                    "multiple_screens_download_google_play",
                    "click"
                  )
                }
                href="https://play.google.com/store/apps/details?id=id.co.myhomecredit&hl=in"
              >
                <img
                  className="download-icon"
                  src={playStoreIcon}
                  alt="play-store"
                />
              </a>
            </div>
            <div>
              <p className="footer-bold-12">Hubungi Kami</p>
              <Contact
                href="mailto:care@homecredit.co.id"
                contact="care@homecredit.co.id"
                contactIcon={emailIcon}
              />
              <Contact
                href="tel:+622129539600"
                contact="021 2953 9600"
                contactIcon={phoneIcon}
              />
              <Contact
                href="https://wa.me/622129539600"
                contact="021 2953 9600"
                contactIcon={whatsappIcon}
              />
            </div>
            <div className="social-media-icons">
              <Icons
                iconId="linkedin-icon"
                iconSrc={linkedinIcon}
                url="https://www.linkedin.com/company/homecreditindonesia/"
              />
              <Icons
                iconId="facebook-icon"
                iconSrc={facebookIcon}
                url="https://www.facebook.com/homecreditid/"
              />
              <Icons
                iconId="ig-icon"
                iconSrc={igIcon}
                url="https://www.instagram.com/homecreditid/"
              />
              <Icons
                iconId="twitter-icon"
                iconSrc={twitterIcon}
                url="https://twitter.com/homecreditid"
              />
              <Icons
                iconId="youtube-icon"
                iconSrc={youtubeIcon}
                url="https://www.youtube.com/channel/UCbFe7opCDSZ3sKLV42MtCfA"
              />
              <Icons
                iconId="tiktok-icon"
                iconSrc={tiktokIcon}
                url="https://www.tiktok.com/@homecreditid?lang=en"
              />
            </div>
            <div>
              <img
                src={ojkIconMobile}
                style={{ height: "34px", margin: "10px" }}
                alt="ojk-logo"
              />
            </div>
            <div>
              <div className="sitemap-tnc">
                <p className="sitemap-tnc-inner">
                  <Link href="/sitemap" as="/sitemap">
                    <a>Sitemap</a>
                  </Link>
                  &nbsp;|&nbsp;
                  <Link href="/syarat-dan-ketentuan" as="/syarat-dan-ketentuan">
                    <a>Syarat dan Ketentuan</a>
                  </Link>
                  &nbsp;|&nbsp;
                  <Link href="/kebijakan-privasi" as="/kebijakan-privasi">
                    <a>Kebijakan Privasi</a>
                  </Link>
                </p>
              </div>
              <hr />
              <div className="copyright">
                &#9400;{yearConst} Home Credit Indonesia
              </div>
            </div>
          </div>
          <div className="footer-bot-title footer-bold-12">
            <p>Hubungi Kami</p>
          </div>
          <div className="bottom-footer-components">
            <div className="left-component">
              <img className="footer-logo" src={LogoWhite} alt="logo" />
              <div className="company-description">
                <p>
                  PT Home Credit Indonesia Plaza Oleos Lantai 8 Jl. TB
                  Simatupang No. 53A, Jakarta Selatan 12520
                </p>
              </div>
              <div className="social-media-icons">
                <Icons
                  iconSrc={linkedinIcon}
                  url="https://www.linkedin.com/company/homecreditindonesia/"
                />
                <Icons
                  iconSrc={facebookIcon}
                  url="https://www.facebook.com/homecreditid/"
                />
                <Icons
                  iconSrc={igIcon}
                  url="https://www.instagram.com/homecreditid/"
                />
                <Icons
                  iconSrc={twitterIcon}
                  url="https://twitter.com/homecreditid"
                />
                <Icons
                  iconSrc={youtubeIcon}
                  url="https://www.youtube.com/channel/UCbFe7opCDSZ3sKLV42MtCfA"
                />
                <Icons
                  iconId="tiktok-icon"
                  iconSrc={tiktokIcon}
                  url="https://www.tiktok.com/@homecreditid?lang=en"
                />
              </div>
            </div>
            <div className="right-component">
              <div className="company-contact">
                <Contact
                  href="mailto:care@homecredit.co.id"
                  contact="care@homecredit.co.id"
                  contactIcon={emailIcon}
                />
                <Contact
                  href="tel:+622129539600"
                  contact="021 2953 9600"
                  contactIcon={phoneIcon}
                />
                <Contact
                  href="https://wa.me/622129539600"
                  contact="021 2953 9600"
                  contactIcon={whatsappIcon}
                />
              </div>
              <div className="download-container">
                <p className="footer-bold-12">Unduh My Home Credit App</p>
                <div>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/id/app/my-home-credit-indonesia/id1313113736"
                    onClick={() =>
                      GACustomEvent(
                        "multiple_screens_download_app_store",
                        "click"
                      )
                    }
                  >
                    <img
                      className="download-icon"
                      src={appStoreIcon}
                      alt="app-store"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=id.co.myhomecredit&hl=in"
                    onClick={() =>
                      GACustomEvent(
                        "multiple_screens_download_google_play",
                        "click"
                      )
                    }
                  >
                    <img
                      className="download-icon"
                      src={playStoreIcon}
                      alt="play-store"
                    />
                  </a>
                </div>
              </div>
              <div style={{ margin: "10px -4px 0 0" }}>
                <img
                  src={ojkIconDesktop}
                  style={{ height: "38px" }}
                  alt="ojk-logo"
                />
              </div>
            </div>
          </div>
          <div className="sitemap-copyrights">
            <div className="sitemap-tnc">
              <p className="sitemap-tnc-inner">
                <Link href="/sitemap" as="/sitemap">
                  <a>Sitemap</a>
                </Link>
                &nbsp;|&nbsp;
                <Link href="/syarat-dan-ketentuan" as="/syarat-dan-ketentuan">
                  <a>Syarat dan Ketentuan</a>
                </Link>
                &nbsp;|&nbsp;
                <Link href="/kebijakan-privasi" as="/kebijakan-privasi">
                  <a>Kebijakan Privasi</a>
                </Link>
              </p>
            </div>
            <div className="copyright">
              &#9400;{yearConst} Home Credit Indonesia
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .footer-container-wrapper-bot {
          display: flex;
          justify-content: center;
          background-color: var(--color-black);
          width: 100%;
        }
        .footer-container-bot {
          width: 100%;
          padding: 10px;
          color: var(--color-gray-lighter);
          display: flex;
          flex-direction: column;
          max-width: 960px;
        }
        .footer-container-mobile {
          display: none;
          width: 75%;
          font-size: 12px;
        }
        .sitemap-tnc {
          font-size: 10px;
        }
        .sitemap-tnc a {
          color: var(--color-gray-lighter);
        }
        .sitemap-tnc-inner {
          display: flex;
        }
        .footer-bot-title {
          display: flex;
          color: var(--color-white);
          justify-content: flex-end;
          width: 100%;
        }
        .bottom-footer-components {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
        }
        .left-component {
          width: 50%;
        }
        .right-component {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
        }
        .footer-bold-12 {
          font-weight: bold;
          font-size: 12px;
        }
        .footer-logo {
          height: 17px;
        }
        .contact-footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .contact-footer img {
          margin-right: 5px;
        }
        .icons {
          height: 20px;
          margin-right: 10px;
        }
        .social-media-icons img:hover {
          filter: brightness(1.8);
        }
        .icons:hover {
          cursor: pointer;
        }
        .sitemap-copyrights {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .copyright {
          font-size: 12px;
        }
        .download-icon {
          height: 40%;
          margin-left: 20px;
        }
        .download-icon:hover {
          cursor: pointer;
        }
        .download-container p {
          text-align: right;
        }
        @media only screen and (max-width: 600px) {
          .footer-container-bot {
            color: var(--color-white);
            align-items: center;
            text-align: center;
            padding: 24px 0 24px 0;
          }
          .footer-container-mobile {
            height: 460px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
          .footer-container-mobile a {
            display: flex;
            align-items: center;
            margin: 0 3px;
          }
          .footer-logo {
            height: 21px;
          }
          .footer-center-mobile {
            padding: 10px;
            display: flex;
          }
          .contact-footer {
            justify-content: center;
          }
          .social-media-icons {
            margin: 0;
            display: flex;
            justify-content: space-around;
          }
          .social-media-icons img {
            height: 28px;
          }
          .footer-bot-title,
          .bottom-footer-components,
          .sitemap-copyrights {
            display: none;
          }
          .footer-list-wrapper {
            display: block;
          }
          .download-icon {
            height: 100%;
            margin: 0;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

const footer = () => {
  return (
    <React.Fragment>
      <div className="footer-outer-wrapper">
        <TopFooter />
        <BottomFooter />
      </div>
      <style jsx>{`
        .footer-outer-wrapper {
          width: 100%;
        }
      `}</style>
    </React.Fragment>
  );
};

export default footer;
